import React from "react";
import styles from "../../style";
import ChartSection from "./ChartSection";
import useAdminLogin from "../adminLogin/useAdminLogin";

export default function TrendFollower() {
  const [adminLoggedIn, { logoutFunc }] = useAdminLogin();
  if (adminLoggedIn)
    return (
      <>
        <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
          <div className={`${styles.boxWidth}`}>
            <div className={`${styles.heading3} py-2`}>Actions</div>
            <ChartSection defaultHours={24 * 10} autoUpdate={false} />
          </div>
        </div>
      </>
    );
}
