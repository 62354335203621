import axios from "axios";
import InputDate from "../../form/inputDate";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import styles from "../../style";
import { optionsLine } from "./chartOptions";
import { dataMultiLineChart } from "./chartDataGenerator";
import { Chart as ChartJS, registerables } from "chart.js";
import { useLocation } from "react-router-dom";
ChartJS.register(...registerables);

export default function ChartSection({ defaultHours }) {
  // > set url by url
  const location = useLocation();
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_API_URL}/user/getall`
  );
  console.log("debug", location.pathname);
  useEffect(() => {
    if (location.pathname.includes("growth-all")) {
      setUrl(`${process.env.REACT_APP_API_URL}/user/getall`);
      console.log("updated url for all!");
    } else {
      setUrl(`${process.env.REACT_APP_API_URL}/user/getallactive`);
      console.log("updated url for active only!");
    }
  }, [location.pathname]);
  // < set url by url
  const [alertM, setAlertM] = useState({
    status: "hidden",
    message: "",
  });
  const [cams, setCams] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  // ------> date and time input
  // base date
  const today = new Date();
  // get ISO format string but Local time
  let now = today.toLocaleString("sv", {
    timeZone: process.env.TIME_ZONE,
  });
  // get some time ago ISO format string but Local time
  let start = new Date(
    today.setHours(today.getHours() - defaultHours)
  ).toLocaleString("sv", { timeZone: import.meta.TIME_ZONE });
  // <------ date and time input

  const [tooManyData, setTooManyData] = useState(false); // not to load too many data
  const [inputDate, setInputDate] = useState({
    end_date: now.slice(0, 10),
    // start_date: new Date(today.setFullYear(today.getFullYear() - 1))
    // start_date: new Date(today.setMonth(today.getMonth() - 1))
    start_date: start.slice(0, 10),
    end_time: now.slice(11, 16),
    start_time: start.slice(11, 16),
    // ----> for this project only
    cam_id: 1,
    roi_id: 1,
    col: "raw1",
  });

  useEffect(() => {
    // get active members
    let config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("at")}`,
        accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        console.log("debug #1", res.data);
        setCams(res.data);
        // setCams(res.data);
      })
      .catch((err) => {
        console.error("API", err.toJSON());
        setAlertM({ status: "bg-red-300", message: err.response.data });
      });
  }, [url]);

  useEffect(() => {
    if (cams.length === 0) return;
    setDataArr([]);
    // ----> check period is too long, dif is hours
    const dif =
      (new Date(inputDate.end_date + " " + inputDate.end_time) -
        new Date(inputDate.start_date + " " + inputDate.start_time)) /
      1000 /
      60 /
      60;
    console.log("Period is ", dif);
    if (dif > 24 * 366) {
      setTooManyData(true);
      return;
    } else {
      setTooManyData(false);
    }
    // <---- check period is too long
    // setDataArr({});
    // setColorArr({});
    // setCameraNameArr({});
    cams.forEach((cam, idx) => {
      // get module_type for color
      let params = {
        user_id: cam.id,
        end_date_str: inputDate.end_date,
        start_date_str: inputDate.start_date,
      };
      // let key = cam.username;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/follower/trend_follower_by_user_and_date`,
          {
            params: params,
          }
        )
        .then((res) => {
          console.log("debug #2", res.data);

          let dataset = res.data;
          // update dataArr
          setDataArr((current) => [...current, dataset]);
        })
        .catch((err) => {
          console.error("API", err.toJSON());
          setAlertM({ status: "bg-red-300", message: err.response.data });
        });
    });
  }, [cams, inputDate]);

  const handleChange = (evt) => {
    let value = evt.target.value;
    let name = evt.target.name;
    setInputDate({ ...inputDate, [name]: value });
  };

  console.log("debug #3", dataArr);
  // console.log("debug", donutArr);

  // -------------------------------------------------------------> for chart

  // ------> for example
  // const dataLine = dataLineChart(Object.values(result), labels);
  // const dataBar = dataBarChart(Object.values(result), labels, colors);
  // <------> for example

  // <-------------------------------------------------------------- for chart
  return (
    <div className="text-gray-600">
      {/* ----------> date input */}
      <div className="flex flex-col sm:flex-row justify-end space-x-2">
        {/* <p className="text-sm font-bold text-gray-600">Period</p> */}
        <InputDate
          name="start_date"
          title="Start Date"
          value={inputDate.start_date}
          handleChange={handleChange}
        />
        {/* <InputTime
            name="start_time"
            title="Time"
            value={inputDate.start_time}
            handleChange={handleChange}
          /> */}
        <InputDate
          name="end_date"
          title="End Date"
          value={inputDate.end_date}
          handleChange={handleChange}
        />
        {/* <InputTime
            name="end_time"
            title="Time"
            value={inputDate.end_time}
            handleChange={handleChange}
          /> */}
      </div>
      {tooManyData && (
        <div className="flex">
          <div className="ml-auto text-orange-300 animate-bounce ">
            Not loading data because selected period is too long. Max period is
            5 days.
          </div>
        </div>
      )}
      {/* <---------- date input */}
      {/* <div className={`${styles.heading3} py-2`}>History Data</div> */}
      {/* <div className="w-[90vw]"> */}
      <div className="mt-4">
        {/* > EXAMPLE */}
        {/* <div className="w-auto p-1 sm:mr-8 h-40">
          <Line data={dataLine} options={optionsLine} />
        </div>
        <div className="w-auto p-1 sm:mr-8 h-40">
          <Bar data={dataBar} options={optionsBar} />
        </div> */}
        {/* < EXAMPLE */}
        {/* {Object.keys(donutArr).length == Object.keys(dataArr).length && */}
        {dataArr.length > 0 &&
          dataArr.map((data, idx) => {
            console.log("debug #5", data);
            return (
              <div key={idx} className="text-lg text-gray-600 font-bold">
                {/* <div className="flex space-x-4 items-center ">
                  <div
                    className={`w-24 text-base text-center px-2  text-gray_600 rounded `}
                  >
                    {data.username}
                  </div>
                </div> */}
                <div className="mb-7">
                  <div className="relative w-auto p-1 sm:mr-8 h-28 my-auto">
                    {/* <Bar
                      data={dataBarChart(dataArr[key], labels, colorArr[key])}
                      options={optionsBar}
                    /> */}
                    <div className="">
                      <Line
                        data={dataMultiLineChart(
                          data.follower,
                          data.following,
                          data.label
                        )}
                        options={optionsLine}
                      />
                    </div>
                    <div className="absolute top-2 left-5 text-sm text-sky-700 font-normal">
                      {data.username}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
