export const optionsLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: "top",
            align: "end",
            labels: {
                font: {
                    size: 12,
                },
                boxHeight: 8,
            },
        },
        title: {
            display: false,
            text: "Trend Chart",
        },
    },
    scales: {
        x: {
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                maxRotation: 25,
            },
        },
        // y: {
        //     ticks: {
        //         font: { size: 10 },
        //         autoSkip: true,
        //         display: true,
        //     },
        // },
        // >master https://www.chartjs.org/docs/latest/axes/labelling.html
        'y_follower': {
            position: 'right',
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                display: true,
            },
            title: {
                display: true,
                text: "follower"
            }
        },
        'y_following': {
            position: 'left',
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                display: true,
            },
            title: {
                display: true,
                text: "following"
            }
        }
    },
};

export const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    animation: {
        // duration: 1
    },
    plugins: {
        legend: {
            display: false,
            position: "top",
            align: "end",
            labels: {
                font: {
                    size: 12,
                },
                boxHeight: 8,
            },
        },
        title: {
            display: false,
            text: "Trend Chart",
        },
    },
    scales: {
        x: {
            ticks: {
                font: { size: 10 },
                color: "rgb(150,150,150)",
                autoSkip: true,
                maxRotation: 25,
            },
        },
        y: {
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                display: false,
            },
            max: 3,
            min: 0,

        }
    },
};

export const optionsMixed = {
    maintainAspectRatio: false,
    responsive: true,
    animation: {
        // duration: 1
    },
    plugins: {
        legend: {
            display: false,
            position: "top",
            align: "end",
            labels: {
                font: {
                    size: 12,
                },
                boxHeight: 8,
            },
        },
        title: {
            display: false,
            text: "Trend Chart",
        },
    },
    scales: {
        x: {
            ticks: {
                font: { size: 10 },
                color: "rgb(150,150,150)",
                autoSkip: true,
                maxRotation: 25,
            },
        },
        y:
        { // for bar cahrt
            id: "value",
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                display: false,
            },
            max: 3,
            min: 0,

        },
        y1: { // for line chart
            id: "raw",
            ticks: {
                font: { size: 10 },
                autoSkip: true,
                display: false,
            },
            // max: 300, // set y scale max
            min: 0,
        },

    },
};

export const optionsDonut = {
    cutout: 27, // <---- donut thickness
    responsive: true,
    maintainAspectRatio: true,
    animation: {
        // duration: 1
    },
    plugins: {
        legend: {
            position: "right",
            align: "",
            labels: {
                font: {
                    size: 12,
                },
                boxHeight: 4,
                boxWidth: 12,
            },
        },
        title: {
            display: false,
            text: 'Doughnut Chart',
            color: 'blue',
            font: {
                size: 12
            },
            padding: {
                top: 30,
                bottom: 30
            },
            responsive: true,
            animation: {
                animateScale: true, // <---- not working
            }
        }
    }

}