const Alert = ({ alertType, alertMessage }) => {
  return (
    <div
      className={`${alertType} my-3 py-2 px-2 rounded text-md font-bold h-auto text-white text-center `}
      role="alert"
    >
      {alertMessage}
    </div>
  );
};

export default Alert;
