



export const dataLineChart = (data, labels) => {
    return {
        labels,
        datasets: [
            {
                label: "Chart Label",
                data: data,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: "rgb(51, 187, 207)", // <- line color
                backgroundColor: "rgb(51, 187, 207)",
                borderWidth: 1.5,
                pointRadius: 0,
                lineTension: 0.3, // <---- smooth
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
        ],
    };

}

export const dataMultiLineChart = (likeData, followData, unfollowData, blockData, labels) => {
    return {
        labels,
        datasets: [
            {
                label: "follow",
                data: followData,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: "rgb(51, 187, 207)", // <- line color
                backgroundColor: "rgb(51, 187, 207)",
                borderWidth: 1.3,
                pointRadius: 0,
                lineTension: 0.3, // <---- smooth
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
            {
                label: "like",
                data: likeData,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: "rgb(255, 153, 255)", // <- line color
                backgroundColor: "rgb(255, 153, 255)",
                borderWidth: 1,
                pointRadius: 0,
                lineTension: 0.3, // <---- smooth
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
            {
                label: "unfollow",
                data: unfollowData,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: "rgb(178,102, 255)", // <- line color
                backgroundColor: "rgb(178,102, 255)",
                borderWidth: 1,
                pointRadius: 0,
                lineTension: 0.3, // <---- smooth
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
            {
                label: "block",
                data: blockData,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: "rgb(255,102,102)", // <- line color
                backgroundColor: "rgb(255,102,102)",
                borderWidth: 1,
                pointRadius: 1,
                lineTension: 0.3, // <---- smooth
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
        ],
    };

}
export const dataBarChart = (data, labels, colors) => {
    return {
        labels,
        datasets: [
            {
                label: "Label 1",
                data: data,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: colors, // <- line color
                backgroundColor: colors,
                borderWidth: 1,
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
            },
        ],
    };
}


export const dataMixedChart = (values, raws, labels, colors) => {
    return {
        labels,
        datasets: [
            {
                type: 'bar',
                yAxisID: "y",  // <---- this is the key in scale option
                label: "bar chart",
                data: values,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                borderColor: colors, // <- line color
                backgroundColor: colors,
                borderWidth: 1,
                borderCapStyle: "round", // butt round <---- cant see effect
                borderJoinStyle: "bevel",
                barThickness: 4, // bar width
            },
            {
                type: 'line',
                yAxisID: "y1", // <---- this is the key in scale option
                label: "line chart",
                data: raws,
                // borderColor: "rgb(255, 99, 132)", // <- line color
                fill: true,
                // borderColor: "rgba(51, 187, 207,0.2)", // <- line color gray
                // backgroundColor: "rgba(51, 187, 207, 0.2)", // <- line color gray
                borderColor: "rgba(210,220,240,0.5)", // <- line color purpule
                backgroundColor: "rgba(210,220,240,0.5)", // <- line color gray
                borderWidth: 1.5,
                pointRadius: 0,
                lineTension: 0.3, // <---- smooth
                // borderCapStyle: "round", // butt round <---- cant see effect
                // borderJoinStyle: "bevel",
            },
        ],
    };

}

export const dataDonutChart = (data, labels, colors) => {
    return {
        labels: labels,
        datasets: [
            {
                label: 'Donut',
                data: data,
                borderColor: ['rgba(255,206,86,0.2)'],
                backgroundColor: colors,
                // backgroundColor: [
                //     'rgba(232,99,132,1)', // red
                //     'rgba(232,211,6,1)',
                //     'rgba(54,162,235,1)', // blue
                //     'rgba(255,159,64,1)', // orange
                //     'rgba(153,102,255,1)'],
                pointBackgroundColor: 'rgba(255,206,86,0.2)',
            }

        ]
    }
}