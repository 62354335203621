import React, { useState, useEffect } from "react";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";

function useAdminLogin() {
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const route = useNavigate();
  console.log("useAdminLogin running....");
  useEffect(() => {
    const encoded_token = window.localStorage.getItem("at");
    if (!encoded_token) {
      setAdminLoggedIn(false);
      route.push("/admin/login");
    } else {
      let info = jwt(encoded_token);
      if (Date.now() / 1000 - info.exp > 0) {
        console.log("Your session expired. exp at ", info.exp);
        logoutHandler();
      }
      setAdminLoggedIn(true);
    }
  }, []);

  const logoutHandler = () => {
    setAdminLoggedIn(false);
    window.localStorage.removeItem("at");
  };
  return [adminLoggedIn, { logoutFunc: logoutHandler }];
}

export default useAdminLogin;
