import React from "react";
import styles from "./style";
import Navbar from "./Navbar";
import Actions from "./admin/actions/Page";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import AdminLogin from "./admin/adminLogin/page";
import TrendFollower from "./admin/trendFollower/Page";

export default function App() {
  return (
    <BrowserRouter>
      <div className="h-[100vh] flex flex-col bg-primary">
        <div className=" w-full ">
          <div className={`${styles.paddingX} ${styles.flexCenter}`}>
            <div className={`${styles.boxWidth}`}>
              <Navbar />
            </div>
          </div>
        </div>

        <Routes>
          <Route
            path={"/"}
            element={
              <>
                <div
                  className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}
                >
                  <div className={`${styles.boxWidth}`}>Test</div>
                </div>
                <div
                  className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}
                >
                  <div className={`${styles.boxWidth}`}>Test</div>
                </div>
              </>
            }
          />
          <Route path="/actions" element={<Actions />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/growth" element={<TrendFollower />} />
          <Route path="/growth-all" element={<TrendFollower />} />
          {/* <Route path="/labelclass" element={<LabelSetting />} /> */}
        </Routes>
        <div
          className={`bg-primary ${styles.paddingX} ${styles.flexStart} mt-auto`}
        >
          <div className={`${styles.boxWidth}`}>
            <Footer />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}
