import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
    url: "/",
  },
  {
    id: "actions",
    title: "Actions",
    url: "/actions",
  },
  {
    id: "growwth",
    title: "Growth",
    url: "/growth",
  },
  {
    id: "growwth-all",
    title: "GrowthAll",
    url: "/growth-all",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Rewards",
    content:
      "The best credit cards offer some tantalizing combinations of promotions and prizes",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Balance Transfer",
    content:
      "A balance transfer credit card can save you a lot of money in interest charges.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "devs in Bangkok",
    value: "Thailand",
  },
  {
    id: "stats-2",
    title: "designed in Japan",
    value: "Japan",
  },
  {
    id: "stats-3",
    title: "10 years experience",
    value: "Industrial",
  },
];

export const footerLinks = [
  {
    title: "Annotation",
    links: [
      {
        name: "Labeling videos ",
        link: "/apivideo",
      },
      {
        name: "Label setup",
        link: "/labelclass",
      },
    ],
  },
  {
    title: "Data view",
    links: [
      {
        name: "Real time data",
        link: "/rtd",
      },
      {
        name: "History data",
        link: "/chart",
      },
    ],
  },
  {
    title: "How to",
    links: [
      {
        name: "Quick Start",
        link: "/",
      },
      {
        name: "Manual",
        link: "/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];