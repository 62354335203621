import React, { useState } from "react";

import { close, logo_insta, menu } from "./assets";
import { navLinks } from "./constants";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="w-full flex py-6 justify-between items-center navbar ">
      <img src={logo_insta} alt="vnx" className="w-[96px] h-[54px]" />
      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          <Link to={nav.url} key={nav.id}>
            <li
              // key={nav.id}
              className={`${
                index === navLinks.length - 1 ? "mr-0" : "mr-10"
              } font-poppins font-normal cursor-pointer text-gray-600 text-[16px]`}
            >
              {/* <a href={`#${nav.id}`}>{nav.title}</a> */}
              {nav.title}
            </li>
          </Link>
        ))}
      </ul>
      <div className="sm:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          fill="#456"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle((prev) => !prev)}
        />
        <div
          className={`${
            toggle ? "flex" : "hidden"
          } p-6 bg-gray-700 absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar z-10`}
        >
          <ul className="list-none flex flex-col sm:hidden justify-end ml-3 flex-1">
            {navLinks.map((nav, index) => (
              <Link to={nav.url} key={nav.id}>
                <li
                  onClick={() => setToggle((prev) => !prev)}
                  // key={nav.id}
                  className={`${
                    index === navLinks.length - 1 ? "mr-0" : "mb-10"
                  } font-poppins font-normal cursor-pointer text-white text-[16px]`}
                >
                  {/* <a href={`#${nav.id}`}>{nav.title}</a> */}
                  {nav.title}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
