import React from "react";
import styles from "../style";

const normalClass =
  "bg-gray-200 text-sm appearance-none border-2 border-gray-200 rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500";

const InputDate = ({ name, title, value, handleChange }) => {
  // console.log(">>>", name, err);
  return (
    <div className="mb-3">
      <label
        className="block text-gray-600 text-sm font-bold mb-2"
        htmlFor={name}
      >
        {title}
      </label>
      <input
        // className={normalClass}
        className={styles.formCompact}
        id={name}
        name={name}
        type="date"
        value={value}
        onChange={handleChange}
      />
    </div>

    // <div className={className}>
    //   <label htmlFor={name} className="form-label">
    //     {title}
    //   </label>
    //   <input
    //     type={type}
    //     className={`form-control ${err}`}
    //     id={name}
    //     name={name}
    //     value={value}
    //     onChange={handleChange}
    //     placeholder={placeholder}
    //   />
    //   <div className={errDiv}>{errMsg}</div>
  );
};

export default InputDate;
