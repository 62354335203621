import React, { Fragment, useEffect, useState } from "react";
import styles from "../../style";
import Input from "../../form/inputS";
import Alert from "../../form/alert";
import CheckBox from "../../form/cehckbox";
import jwt from "jwt-decode";
import axios from "axios";

export default function AdminLogin() {
  const [adminUser, setAdminUser] = useState({
    username: "",
    password: "",
    scope: "admin",
  });
  // for form validation
  const [errors, setErrors] = useState([]);
  // submit button controll
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // for alert as UI
  const [alertM, setAlertM] = useState({
    status: "hidden",
    message: "",
  });

  const handleChange = (evt) => {
    // bringing target into evt
    let value = evt.target.value;
    let name = evt.target.name;
    if (parseInt(value)) {
      value = parseInt(value);
    }
    setAdminUser({ ...adminUser, [name]: value });
  };

  const hasError = (key) => {
    // console.log("hasError :", errors);
    return errors.indexOf(key) !== -1;
  };

  function postAdminUser() {
    setSubmitting(true);
    console.log("Started posting adminUser...");

    // ------------------> POST AXIOS
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/token`, adminUser, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        console.log(response);
        setSubmitting(false);
        setSubmitSuccess(true);
        setAlertM({
          status: "bg-blue-500",
          message: `ログイン成功！`,
        });
        setAdminUser({
          username: "",
          password: "",
        });
        let info = jwt(response.data.access_token);
        console.log("Decoded Info is ", info);

        // set local storage
        window.localStorage.setItem("at", response.data.access_token);
      })
      .catch((error) => {
        setSubmitting(false);
        console.error(error);
      });
    // <------------------ POST AXIOS

    // ---->>>> POST OLD
    // const myHeaders = new Headers();
    // myHeaders.append("accept", "application/json");
    // myHeaders.append("Content-Type", "application/json");
    // const jsonString = JSON.stringify(adminUser);
    // const requestOptions = {
    //   method: "POST",
    //   body: jsonString,
    //   headers: myHeaders, // added for jwt
    // };

    // fetch(`${process.env.REACT_APP_API_URL}/auth/token`, requestOptions)
    //   .then(async (response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     const failedResponse = await response.json();
    //     throw Error(`投稿エラー：${JSON.stringify(failedResponse)}`);
    //   })
    //   .then((json) => {
    //     // console.log("JWT is ", json.jwt);
    //     // // let info = jwt(json.jwt);
    //     // console.log("Info is ", info);

    //     setAlertM({
    //       status: "bg-blue-500",
    //       message: `ログイン成功！`,
    //     });
    //     setAdminUser({
    //       username: "",
    //       password: "",
    //     });
    //     // set local storage
    //     window.localStorage.setItem("jwt", json.jwt);
    //   })
    //   .catch((error) => {
    //     // error on front
    //     setAlertM({
    //       status: "bg-red-500",
    //       message: `${error}`,
    //     });
    //   })
    //   .finally(() => {
    //     setSubmitting(false);
    //   });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setAlertM({ status: "hidden", message: "" });
    setSubmitting(true);

    // ---->>>> start validation
    let errors = [];
    if (adminUser.username === "") {
      errors.push("username");
      setErrors(errors);
    }
    if (adminUser.password.length < 5) {
      errors.push("password");
      setErrors(errors);
    }
    if (errors.length > 0) {
      setAlertM({ status: "bg-red-500", message: "入力に不備があります" });
      setSubmitting(false);
      return;
    }
    // <<<=== end validation

    // ===>>> set jwt to send to backend
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "multipart/form-data");
    // myHeaders.append("accept", "application/json");
    // myHeaders.append("Authorization", "Bearer " + props.jwts.jwt);
    postAdminUser();
  };

  return (
    <>
      {/* >issue >master
      上下中央ぞろえできない問題
      これで良いが、上位親エレメントがサイズ指定されていない場合はnot work
      >solved すべての親にh-fullつけて
      <div class="flex h-full">
        <div class="m-auto">
        <!-- Your content goes here -->
        </div>
      </div>
    
    */}
      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexStart} h-full `}
      >
        <div className={`${styles.boxWidth} h-full`}>
          <div className={`${styles.heading3} py-2`}>Admin Login</div>

          <div className="flex justify-center w-full h-full">
            <div className="p-2 px-8 pt-6 pb-8 my-auto  ">
              <form onSubmit={handleSubmit}>
                <div className="">
                  {/* <Input
              title="SKU"
              name="sku"
              type="text"
              value={product.sku}
              err={hasError("SKU") ? true : false}
              errMsg={"SKUを記入してください"}
              handleChange={handleChange}
              placeholder=""
            /> */}

                  <Input
                    title="username"
                    name="username"
                    value={adminUser.username}
                    handleChange={handleChange}
                    type="username"
                    placeholder="your@username.com"
                    err={hasError("username") ? true : false}
                    errMsg="usernameを入力してください"
                    className="w-1/2 min-w-[300px]"
                  />
                  <Input
                    title="password"
                    name="password"
                    value={adminUser.password}
                    handleChange={handleChange}
                    type="text"
                    placeholder=""
                    err={hasError("password") ? true : false}
                    errMsg="6文字以上で入力してください"
                    className="w-1/2 min-w-[300px]"
                  />

                  {/* <CheckBox
              title="NO PRICE NO STOK"
              name="no_price_no_stock"
              value={product.no_price_no_stock}
              handleChange={handleChangeCheckBox}
              placeholder="No Pricing Product"
            /> */}
                </div>

                <Alert
                  alertType={alertM.status}
                  alertMessage={alertM.message}
                />
                {submitSuccess ? null : !submitting ? (
                  <button
                    className="mt-4 shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline
            focus:outline-none text-white font-bold py-1 px-4 w-full rounded"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    login
                  </button>
                ) : (
                  "submitting..."
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ===> show form contents */}
      {/* <div className="mt-3">
        <pre>{JSON.stringify(adminUser, null, 3)}</pre>
      </div> */}
    </>
  );
}
